import StackdriverErrorReporter from 'stackdriver-errors-js';

const errorReporter = new StackdriverErrorReporter();

console.log("starting error reporter");

errorReporter.start({
    key: window.appsettings.REACT_APP_ERROR_REPORTING_KEY,
    projectId: window.appsettings.REACT_APP_ERROR_REPORTING_PROJECT_ID,
    service: "survey"
    // additional params can be found here:
    // https://github.com/GoogleCloudPlatform/stackdriver-errors-js

});

export default errorReporter;