import React, { useEffect, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

function CheckboxInput(props) {
  const [checkedBoxes, setCheckedBoxes] = useState(null);
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { inputDefaultValue, inputFieldDef, inputFieldId, setInputData } = props;

  const { register } = useForm({ mode: "onChange" });

  const inputChanged = (e) => {
    if (e.target.checked) {
      if (!checkedBoxes.includes(e.target.value)) {
        checkedBoxes.push(e.target.value);
      }
    } else {
      if (checkedBoxes.includes(e.target.value)) {
        checkedBoxes.splice(checkedBoxes.indexOf(e.target.value), 1);
      }
    }
    setCheckedBoxes(checkedBoxes.map((x) => x));
    setTouched(true);
  };

  const sendInputData = useCallback(
    (id, value, isValid) => {
      let inputData = {
        id: id,
        data: value,
        isValid: isValid,
      };

      setInputData(inputData);
    },
    [setInputData]
  );

  useEffect(() => {
    if (!checkedBoxes) {
      setCheckedBoxes(inputDefaultValue ? inputDefaultValue : []);
    }
  }, [checkedBoxes, inputDefaultValue]);

  useEffect(() => {
    if (inputFieldDef.required && checkedBoxes) {
      setIsValid(checkedBoxes.length > 0);
    } else {
      setIsValid(true);
    }
  }, [checkedBoxes, inputFieldDef.required]);

  useEffect(() => {
    sendInputData(inputFieldId, checkedBoxes, isValid);
  }, [checkedBoxes, isValid, inputFieldId, inputDefaultValue, sendInputData]);

  const isChecked = (optionValue) => {
    if (!checkedBoxes) {
      return false;
    }

    return checkedBoxes.indexOf(optionValue) > -1;
  };

  if (inputFieldDef) {
    return (
      <Form.Group className="question-form-checkbox form-group w-100 mb-2" controlId={inputFieldId}>
        <Form.Label className="fs-5 fw-light mb-3">{inputFieldDef.text?.title}</Form.Label>

        {inputFieldDef.text?.explain ? <div className="small text-muted mb-3">{inputFieldDef.text.explain}</div> : null}

        {inputFieldDef.options.map((option, index) => {
          return (
            <Form.Check
              key={`option-${inputFieldId}-${option.value}`}
              id={`option-${inputFieldId}-${option.value}`}
              name={inputFieldId}
              type={inputFieldDef.type}
              label={option?.title}
              // onChange={inputChanged}
              value={option.value}
              checked={isChecked(option.value)}
              feedbackType="invalid"
              className="fw-bold"
              isInvalid={!isValid && touched}
              {...register(inputFieldId, {
                onChange: (e) => {
                  inputChanged(e);
                },
                required: inputFieldDef.required,
              })}
            />
          );
        })}
      </Form.Group>
    );
  }
}

export default CheckboxInput;
