import React, { useRef, useState } from "react";
import TextInput from "./inputs/TextInput";
import CheckboxInput from "./inputs/CheckboxInput";
import RadioInput from "./inputs/RadioInput";
import Textarea from "./inputs/TextareaInput";
import SelectInput from "./inputs/SelectInput";
import MultiSelectInput from "./inputs/MultiSelectInput";
import QuestionHeader from "./QuestionHeader";
import LoadingQuestion from "./LoadingQuestion";
import { autoId } from "../utils/StringHelpers";

function FormInputs(props) {
  const { setAnswerData, question, answer } = props;
  const [, setTouched] = useState("");
  let combinedAnswerData = useRef({});
  let combinedMetaData = useRef({});
  let inputsValid = useRef({});

  const isAnswerLoaded = answer ? true : false;

  const areAllQuestionsAnswered = () => {
    let isComplete = true;
    Object.keys(inputsValid.current).forEach((fieldName) => {
      if (!inputsValid.current[fieldName]) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const findDependentQuestions = (inputData) => {
    let dependentQuestions = [];
    const field = inputData.id;

    Object.keys(question).forEach((key) => {
      if (question[key].dependsOn) {
        Object.keys(question[key].dependsOn).forEach((dependsOnKey) => {
          if (dependsOnKey === field) {
            dependentQuestions.push(key);
          }
        });
      }
    });

    return dependentQuestions;
  };

  // Reset all dependent questions if the parent question is changed
  // and the child question is no longer visible
  const resetDependentQuestions = (parent, childQuestions) => {
    const currentValueOfParentQuestion = parent.data;

    childQuestions.forEach((childQuestionId) => {
      const childQuestion = question[childQuestionId];
      const childQuestionDependsOn = childQuestion.dependsOn[parent.id];

      const mustMatchValue = childQuestionDependsOn.value;

      if (currentValueOfParentQuestion !== mustMatchValue) {
        combinedAnswerData.current[childQuestionId] = undefined;
        combinedMetaData.current[childQuestionId] = undefined;

        if (childQuestionDependsOn.actionOnFail === "disable") {
          inputsValid.current[childQuestionId] = false;
        } else {
          delete inputsValid.current[childQuestionId];
        }
      }
    });
  };

  const setInputData = (inputData) => {
    let fieldData = {};
    fieldData[inputData.id] = inputData.data;

    const dependentQuestions = findDependentQuestions(inputData);

    resetDependentQuestions(inputData, dependentQuestions);

    let metaData = {};

    metaData[inputData.id] = inputData.meta ?? {};

    let localAnswerData = {
      ...combinedAnswerData.current,
      ...fieldData,
    };

    let localMetaData = {
      ...combinedMetaData.current,
      ...metaData,
    };

    if (inputData.isValid === undefined) {
      delete inputsValid.current[inputData.id];
    } else {
      inputsValid.current[inputData.id] = inputData.isValid;
    }

    combinedAnswerData.current = localAnswerData;
    combinedMetaData.current = localMetaData;

    setAnswerData(
      question.id,
      {
        data: localAnswerData,
        meta: localMetaData,
        isComplete: areAllQuestionsAnswered(),
      },
      null,
      { collectClientData: true }
    );

    if (inputData.touched) {
      // This is for the dependsOn property of the surveyDef
      // This allows a child component to trigger a re-render so that any dependent
      // questions can re-bind with the new sibling values.
      setTouched(autoId());
    }
  };

  const getComponent = (field, index) => {
    let inputFieldDef = question[field];

    let defaultValue = null;

    if (answer) {
      defaultValue = answer?.data && answer.data[field] ? answer.data[field] : null;
    }

    const focus = defaultValue === null && index === 0;

    let disabled = false;
    let hidden = false;

    if (inputFieldDef.dependsOn) {
      Object.keys(inputFieldDef.dependsOn).forEach((key) => {
        const dependsOn = inputFieldDef.dependsOn[key];

        const currentValueOfDependent = combinedAnswerData.current[key] ?? answer.data?.[key];

        if (dependsOn.value !== currentValueOfDependent) {
          if (dependsOn.actionOnFail === "disable") {
            disabled = true;
          } else {
            hidden = true;
          }
        }
      });
    }

    if (disabled) {
      inputsValid.current[field] = false;
    } else if (hidden) {
      inputsValid.current[field] = true;
    }

    let inputProps = {
      inputFieldId: field,
      inputFieldDef: inputFieldDef,
      inputDefaultValue: defaultValue,
      setInputData: setInputData,
      disabled: disabled,
      hidden: hidden,
    };

    const getComponent = (componentType) => {
      switch (componentType) {
        case "text":
          return <TextInput {...inputProps} focus={focus} />;
        case "email":
          return <TextInput {...inputProps} focus={focus} />;
        case "number":
          return <TextInput {...inputProps} focus={focus} />;
        case "tel":
          return <TextInput {...inputProps} focus={focus} />;
        case "date":
          return <TextInput {...inputProps} focus={focus} />;
        case "textarea":
          return <Textarea {...inputProps} focus={focus} />;
        case "checkbox":
          return <CheckboxInput {...inputProps} />;
        case "radio":
          return <RadioInput {...inputProps} />;
        case "select":
          return <SelectInput {...inputProps} />;
        case "multiselect":
          return <MultiSelectInput {...inputProps} />;
        default:
          throw new Error(`Unknown input type: ${componentType}`);
      }
    };

    return !inputProps.hidden ? (
      <div className="form-question" key={index}>
        {getComponent(inputFieldDef.type)}
      </div>
    ) : null;
  };

  if (isAnswerLoaded) {
    return (
      <div className="form-inputs mx-auto">
        <QuestionHeader question={question} />

        <div className="form-input-group bg-white shadow-sm w-100 p-2 p-md-5 mt-4 mx-auto">
          {question.fieldOrder.map((field, index) => {
            return getComponent(field, index);
          })}
        </div>
      </div>
    );
  } else {
    return <LoadingQuestion />;
  }
}

export default FormInputs;
