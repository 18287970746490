import { createContext, useReducer } from "react";

const pageSettings = {
  fullscreen: false,
  showSurveyNav: true,
  darkBackground: false,
  showProgressBar: true,
  answer: {
    id: null,
    data: null,
    isComplete: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RECORD_VIDEO_START":
      return { ...state, fullscreen: true, darkBackground: true, showSurveyNav: false, showProgressBar: false };
    case "RECORD_VIDEO_STOP":
      return { ...state, fullscreen: false, darkBackground: false, showSurveyNav: true, showProgressBar: true };
    case "FULLSCREEN_ADD":
      return { ...state, fullscreen: true };
    case "FULLSCREEN_REMOVE":
      return { ...state, fullscreen: false };
    case "SURVEYNAV_ADD":
      return { ...state, showSurveyNav: true };
    case "SURVEYNAV_REMOVE":
      return { ...state, showSurveyNav: false };
    case "ANSWER_LOADED":
      return {
        ...state,
        ...action.payload,
        answer: {
          ...action.payload.answer,
        },
      };
    default:
      throw new Error();
  }
};

export const PageContext = createContext();

export const PageContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, pageSettings);

  return <PageContext.Provider value={[state, dispatch]}>{props.children}</PageContext.Provider>;
};
