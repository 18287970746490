import React, { useEffect, useState, useContext, useRef } from "react";
import { Outlet } from "react-router-dom";
import { signInWithCustomToken } from "firebase/auth";
import { auth, authenticateSurvey } from "../services/Firebase";
import { useParams } from "react-router-dom";
import Unauthorized from "./Unauthorized";
import { AppContext } from "../context/AppContext";

const ProtectedRoutes = () => {
  const params = useParams(); // url params
  const [, dispatch] = useContext(AppContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [elem, setElem] = useState(<Loading />);
  const isAuthenticated = useRef(false);

  useEffect(() => {
    if (auth.currentUser) {
      setIsSignedIn(true);
      isAuthenticated.current = false;
    } else if (!isAuthenticated.current) {
      isAuthenticated.current = true;
      authenticateSurvey({ surveyResponseId: params.surveyResponseId, tokenId: params.tokenId })
        .then((result) => {
          console.log("authenticated", result.data);
          const token = result.data.token;
          dispatch({ type: "AUTHENTICATED", payload: result.data });

          signInWithCustomToken(auth, token)
            .then((userCredential) => {
              console.log("signed in", userCredential.user);
              setIsSignedIn(true);
              isAuthenticated.current = false;
            })
            .catch((error) => {
              console.error("error", error);
              setIsSignedIn(false);
              isAuthenticated.current = false;
            });
        })
        .catch((error) => {
          // signin failed
          console.log("INVALID SURVEY TOKEN", error);
          setElem(<Unauthorized />);
          isAuthenticated.current = false;
        });
    }
  }, [dispatch, params.surveyResponseId, params.tokenId]);

  useEffect(() => {
    if (isSignedIn) {
      setElem(<Outlet />);
    }
  }, [isSignedIn]);

  return elem;
};

export default ProtectedRoutes;

function Loading() {
  return (
    <div className="position-relative h-100">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="spinner-border app-spinner" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}
