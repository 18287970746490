import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "./helpers/Spinner";
import SendGridService from "../services/SendGridService";

function LocationEmailLinkModal(props) {
  // emailData: subject, html
  const { emailData, showModal, toggleModal, deviceType } = props;

  const [email, setEmail] = useState("");
  const [buttonBlocked, setButtonBlocked] = useState(true);
  const [emailLoading, setEmailLoading] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setTimeout(() => {
        setEmailLoading(false);
        setEmailSent(false);
        setEmail("");
      }, 500);
    }
  }, [showModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailLoading(true);

    const { subject, html } = emailData;
    if (!subject || !html) {
      alert("Email data must contain fields: subject, html");
      return;
    }

    SendGridService.sendEmail({ to: email, ...emailData })
      .then((response) => {
        setEmailSent(true);
      })
      .catch((err) => {
        alert("Error sending email");
        console.log("Error sending email:", err);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const validateEmail = (e) => {
    setButtonBlocked(!e.target.checkValidity());
  };

  return (
    <Modal show={showModal} onHide={toggleModal} centered backdrop="static">
      <Modal.Header closeButton>
        {isEmailSent && <span className="fw-bold">Email sent successfully!</span>}
      </Modal.Header>
      <Modal.Body className="px-4">
        {!isEmailSent ? (
          <>
            <div className="fw-600 mt-3">
              {deviceType === "desktop"
                ? "Enter an email address that delivers to your desktop computer or laptop and we will send a link that will allow you to continue the survey."
                : "Enter an email address that delivers to your smart phone and we will send a link that will allow you to continue the process on your mobile device."}
            </div>

            <Form onSubmit={handleSubmit} onChange={validateEmail}>
              <Form.Group className="my-4" controlId="email">
                <Form.Label className="text-muted">Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  autoFocus={true}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@example.com"
                />
              </Form.Group>

              <button
                type="submit"
                className="rounded-5 btn btn-primary text-white p-3 mb-3 w-100"
                disabled={buttonBlocked || emailLoading}
              >
                {emailLoading ? <Spinner color="white" size="20px" helperText="Sending Email..." /> : "Send Link"}
              </button>
            </Form>
          </>
        ) : (
          <>
            <div className="fw-600 my-3">
              {deviceType === "desktop"
                ? "Open the email on your desktop computer or laptop to continue."
                : "Open the email on your smart phone to continue."}
            </div>
            <button className="rounded-5 btn btn-primary text-white p-3 mb-3 w-100" onClick={toggleModal}>
              Close
            </button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default LocationEmailLinkModal;
