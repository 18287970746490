import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "./Firebase";
import { autoId } from "../utils/StringHelpers";

const mailCollectionRef = collection(db, "mail");

export const qrLinkUrl = () => `${window.location.href}?linked=true`;

export const surveyQrLinkData = () => {
  return {
    subject: "Survey link",
    html: `
      <div>Open this email on your mobile device and click the link below to continue</div>
      <a href="${qrLinkUrl()}">Continue</a>
    `,
  };
};

const SendGridService = {
  sendEmail: async (data) => {
    const { to, subject, html } = data;

    try {
      if (!data.id) {
        data.id = autoId();
      }

      console.log("Sending email", data);

      const mailDocRef = await setDoc(doc(mailCollectionRef, data.id), {
        to,
        message: {
          subject,
          html,
        },
      });
      return mailDocRef;
    } catch (error) {
      console.error("Error sending email", error);
    }
  },
};

export default SendGridService;
