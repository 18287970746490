import { useState, useEffect, useCallback } from "react";

// Simple local storage hook.
// Provide Key to store the value in local storage.
// Initial value of the key.
// onChangeEvent is optional, incase you want to do something on value change.
// ex: On theme change, the light theme does not properly aggregate all colors through the app,
// so we can trigger a refresh on the page to properly load all the colors.
export const useLocalStorage = (key, initialValue, onChangeEvent) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error("Error retrieving from local storage:", error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        const oldValue = window.localStorage.getItem(key);
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));

        // Dispatch the storage event -- Just to keep states up to date without refresh.
        const storageEvent = new StorageEvent("storage", {
          key: key,
          oldValue: oldValue,
          newValue: JSON.stringify(valueToStore),
          url: window.location.href,
          storageArea: localStorage,
        });

        window.dispatchEvent(storageEvent);
        if (onChangeEvent) {
          onChangeEvent(valueToStore);
        }
      } catch (error) {
        console.error("Error setting value in local session:", error);
      }
    },
    [key, storedValue, onChangeEvent]
  );

  // We can watch the value and apply state change on the updated value. Good for debugging.
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === key && e.newValue !== e.oldValue) {
        setValue(JSON.parse(e.newValue));
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, setValue]);

  return [storedValue, setValue];
};
