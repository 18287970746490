import ReactMarkdown from "react-markdown";
import Accordion from "react-bootstrap/Accordion";

function QuestionHeader(props) {
  const { isVideoUploaded } = props;
  const hasTitle = props.question.text && props.question.text.title;
  const hasExplain = props.question.text && props.question.text.explain;
  const hasPhoto = props.question.media && props.question.media.photo;

  if (!props.question.text) {
    return null;
  }

  const Title = () => {
    if (!hasTitle) {
      return null;
    }

    return <h1 className="mb-4 text-center">{props.question.text.title}</h1>;
  };

  const Explain = () => {
    if (!hasExplain) {
      return null;
    }

    // return <ReactMarkdown className="markdown fs-6 mb-5 text-center">{props.question.text.explain}</ReactMarkdown>;
    return (
      <>
        <ReactMarkdown className="markdown text-center">{props.question.text.explain}</ReactMarkdown>
        {props.question.text.instructions && (
          <div className="accordion-wrap shadow-sm mb-4">
            <Accordion defaultActiveKey={isVideoUploaded ? undefined : "0"} className="instructions" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <strong>{props.question.text.instructions.title}</strong>
                </Accordion.Header>
                <Accordion.Body className="pt-0 show">
                  <ReactMarkdown className="markdown text-center">
                    {props.question.text.instructions.info}
                  </ReactMarkdown>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </>
    );
  };

  const Photo = () => {
    if (!hasPhoto) {
      return null;
    }

    return (
      <div className="explain-img overflow-hidden mx-auto">
        <img className="img-fluid" src={props.question.media.photo.url} alt={props.question.media.photo.alt} />
      </div>
    );
  };

  const SubHeader = () => {
    if (!hasExplain && !hasPhoto) {
      return null;
    }

    return (
      <div className="sub-header">
        <Explain />
        <Photo />
      </div>
    );
  };

  return (
    <div className="question-header w-100">
      <Title />
      <SubHeader />
    </div>
  );
}

export default QuestionHeader;
