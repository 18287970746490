import { doc, setDoc, collection, getDoc, getDocs } from "firebase/firestore";
import { autoId } from "../utils/StringHelpers";

import { db } from "./Firebase";

const surveysCollectionRef = collection(db, "surveys");

const SurveyService = {
  fetchSurvey: async (surveyId) => {
    try {
      const surveysDocSnap = await getDoc(doc(surveysCollectionRef, surveyId));
      const survey = surveysDocSnap.data();

      return survey;
    } catch (e) {
      console.error(`Survey not found: ${e}`);
    }
  },
  fetchAll: async () => {
    try {
      const surveysDocSnap = await getDocs(surveysCollectionRef);

      const surveys = [];
      surveysDocSnap.forEach((doc) => {
        surveys.push(doc.data());
      });

      return surveys;
    } catch (e) {
      console.error(`Survey not found: ${e}`);
    }
  },
  add: async (data) => {
    try {
      if (!data.id) {
        data.id = autoId();
      } else {
        data.lastModified = new Date().toISOString();
      }

      if (!data.created) {
        data.created = new Date().toISOString();
      }

      const surveyDocRef = await setDoc(doc(surveysCollectionRef, data.id), data);

      return surveyDocRef;
    } catch (error) {
      console.error("add survey error", error);
    }
  },
};

export default SurveyService;
