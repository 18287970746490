function LoadingQuestion() {
  return (
    <div className="d-flex position-relative text-center" style={{ height: "70vh" }}>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="spinner-border app-spinner" role="status">
          <span className="visually-hidden">Fetching Question...</span>
        </div>
        <div className="mt-3 text-center fw-600 text-theme-primary">Fetching Question...</div>
      </div>
    </div>
  );
}

export default LoadingQuestion;
