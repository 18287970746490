export const getModerationStatus = (acceptableFieldSettings, isAcceptable, defaultModerationStatus) => {
  const surveyQuestionModSettings = acceptableFieldSettings?.moderationStatus;

  if (!defaultModerationStatus) {
    defaultModerationStatus = "";
  }

  if (isAcceptable === true) {
    if (surveyQuestionModSettings?.isAcceptable) {
      return surveyQuestionModSettings.isAcceptable;
    } else {
      return surveyQuestionModSettings?.default ? surveyQuestionModSettings.default : defaultModerationStatus;
    }
  }

  if (isAcceptable === false) {
    if (surveyQuestionModSettings?.isNotAcceptable) {
      return surveyQuestionModSettings.isNotAcceptable;
    } else if (surveyQuestionModSettings?.default) {
      return surveyQuestionModSettings?.default ?? "";
    } else if (defaultModerationStatus) {
      return defaultModerationStatus;
    } else {
      return "needsreview";
    }
  }

  return defaultModerationStatus;
};
