import QRCode from "react-qr-code";

function DisplayQRCode(props) {
  const { linkUrl, question } = props;

  return (
    <div className="text-center">
      <QRCode value={linkUrl} title="QR code" className="p-3" />
      {question?.text?.explainQR && <p className="mt-3 mb-0">{question.text.explainQR}</p>}
    </div>
  );
}

export default DisplayQRCode;
