import React from "react";
import App from "./App";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import SurveyManager from "./components/SurveyManager";
import StartSurvey, { startSurvey } from "./components/StartSurvey";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { AppContextProvider } from "./context/AppContext";

import "bootstrap/dist/css/bootstrap.css";
import "material-icons/iconfont/material-icons.css";
import "material-symbols";
import "@fontsource/open-sans";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/500-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/700-italic.css";
import "./scss/App.scss";
import ProtectedAdminRoutes from "./components/ProtectedAdminRoutes";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<ProtectedAdminRoutes />}>
        <Route exact path="/surveymanager" element={<SurveyManager />} />
      </Route>
      <Route path="/start/:surveyId" element={<StartSurvey />} loader={async ({ params }) => startSurvey(params)} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/:surveyResponseId/:tokenId/:pageId" element={<App />} />
        <Route path="/:surveyResponseId/:tokenId/" element={<App />} />
      </Route>
    </Route>
  ),
  { future: { v7_startTransition: true } }
);

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <RouterProvider router={router} />
    </AppContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
