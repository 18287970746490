import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import logo from "../assets/active-comply-logo.svg";
import SurveyService from "../services/SurveyService";
import { Alert, CardBody, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const SurveyManager = (props) => {
  const [accountId, setAccountId] = useState("e6593184d34648e981c8fd8c84084a2f");
  const [surveyId, setSurveyId] = useState("");
  const [surveyTemplate, setSurveyTemplate] = useState("");
  const [showTemplateAssignedAlert, setShowTemplateAssignedAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [surveys, setSurveys] = useState([]);
  const [surveyAdded, setSurveyAdded] = useState(new Date());

  useEffect(() => {
    SurveyService.fetchAll().then((surveys) => {
      setSurveys(surveys);
    });
  }, [surveyAdded]);

  const addUpdateSurvey = () => {
    fetch(`/surveys/${surveyTemplate}.json`)
      .then((response) => response.json())
      .then((json) => {
        setErrorMessage();
        setShowTemplateAssignedAlert(false);

        json.accountId = accountId;
        json.id = `${json.id}_${accountId}`;
        setSurveyId(json.id);

        SurveyService.add(json);
        console.log("survey updated", json);
        setShowTemplateAssignedAlert(true);
        setSurveyAdded(new Date());
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  return (
    <>
      <Container style={{ maxWidth: 900 }}>
        <Card>
          <CardBody className="p-5">
            <Row className="justify-content-center">
              <Col>
                <img className="d-block img-fluid img-logo mt-4 mb-5 mx-auto" src={logo} alt="Active Comply" />

                <div className="mb-4">
                  <Form.Group className="mb-4" controlId="accountId">
                    <Form.Label>Account ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={accountId}
                      onChange={(e) => {
                        setAccountId(e.target.value);
                        setErrorMessage();
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="mb-4">
                  <Form.Group className="mb-4" controlId="accountId">
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="e.g. branchaudit_v2"
                      value={surveyTemplate}
                      onChange={(e) => {
                        setSurveyTemplate(e.target.value);
                        setErrorMessage();
                      }}
                    />
                  </Form.Group>
                </div>

                <button onClick={() => addUpdateSurvey()} className="btn btn-primary btn-pill m-2">
                  Assign Template to Account
                </button>
              </Col>
            </Row>

            {showTemplateAssignedAlert && (
              <Row>
                <Col className="p-3">
                  <Alert variant="success" onClose={() => setShowTemplateAssignedAlert(false)} dismissible>
                    <Alert.Heading>Success!</Alert.Heading>
                    <Link to={`/start/${surveyId}`} target="_blank">
                      View Survey
                    </Link>
                  </Alert>
                </Col>
              </Row>
            )}
            {errorMessage && (
              <Row>
                <Col className="p-3">
                  <Alert variant="danger" onClose={() => setErrorMessage()} className="mb-0" dismissible>
                    <Alert.Heading>{errorMessage.name}</Alert.Heading>
                    <p>{errorMessage.message}</p>
                  </Alert>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
        <Card className="mt-5">
          <CardBody className="p-5">
            <h2 className="mb-4">Existing Surveys</h2>
            <ListGroup>
              {surveys.map((survey) => (
                <ListGroup.Item key={survey.id}>
                  <Link to={`/start/${survey.id}`} target="_blank">
                    {survey.id}
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default SurveyManager;
