import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function SurveyNav(props) {
  const { showPrevious, showNext, handleAction, isPageCompleted } = props;

  if (!showPrevious && (!showNext || !isPageCompleted)) {
    return null;
  }

  return (
    <footer id="survey-nav-wrap">
      <Container fluid="md" className="h-100">
        <Row className="justify-content-center h-100">
          <Col
            lg={10}
            xl={9}
            xxl={8}
            className={
              showPrevious && showNext && isPageCompleted
                ? "d-flex align-items-center justify-content-between"
                : "d-flex align-items-center justify-content-center"
            }
          >
            {showPrevious ? (
              <button
                className="survey-nav app-btn-tertiary text-center fw-600"
                type="button"
                onClick={() => handleAction({ navigate: "previous" })}
              >
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.48248 0.650226C7.66132 0.646423 7.83693 0.696551 7.98492 0.793718C8.1329 0.890886 8.2459 1.03029 8.30821 1.19243C8.37051 1.35456 8.37902 1.53141 8.33255 1.69844C8.28608 1.86547 8.18695 2.01428 8.04893 2.12431L2.78277 6.48657L8.04893 10.8473C8.14441 10.9151 8.22436 11.0013 8.2838 11.1004C8.34323 11.1995 8.38086 11.3095 8.39432 11.4233C8.40779 11.5371 8.39681 11.6524 8.36206 11.7619C8.32732 11.8714 8.26956 11.9729 8.1924 12.0598C8.11524 12.1468 8.02035 12.2173 7.91366 12.2671C7.80697 12.317 7.69079 12.345 7.5724 12.3494C7.454 12.3538 7.33593 12.3346 7.22559 12.2929C7.11525 12.2511 7.01501 12.1878 6.93115 12.1069L0.901881 7.1188C0.807199 7.04067 0.731169 6.9437 0.679034 6.83441C0.6269 6.72511 0.599902 6.60616 0.599902 6.4858C0.599902 6.36544 0.6269 6.24649 0.679034 6.1372C0.731169 6.02791 0.807199 5.93084 0.901881 5.85271L6.93115 0.859849C7.08346 0.729038 7.27882 0.654668 7.48248 0.650034V0.650226Z" />
                </svg>

                <span className="ps-2">Back</span>
              </button>
            ) : null}

            {showNext && isPageCompleted ? (
              <button
                className="survey-nav btn-next app-btn-primary text-center fw-600"
                type="button"
                onClick={() => handleAction({ navigate: "next" })}
                disabled={!isPageCompleted}
              >
                <span className="pe-2">Next</span>
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.51752 0.650226C1.33868 0.646423 1.16307 0.696551 1.01508 0.793718C0.867095 0.890886 0.754096 1.03029 0.691795 1.19243C0.629493 1.35456 0.620984 1.53141 0.667451 1.69844C0.713918 1.86547 0.813051 2.01428 0.951069 2.12431L6.21723 6.48657L0.951069 10.8473C0.855595 10.9151 0.775638 11.0013 0.716205 11.1004C0.656772 11.1995 0.619144 11.3095 0.605676 11.4233C0.592208 11.5371 0.603191 11.6524 0.637937 11.7619C0.672684 11.8714 0.730444 11.9729 0.807602 12.0598C0.88476 12.1468 0.979654 12.2173 1.08634 12.2671C1.19303 12.317 1.30921 12.345 1.4276 12.3494C1.546 12.3538 1.66407 12.3346 1.77441 12.2929C1.88475 12.2511 1.98499 12.1878 2.06885 12.1069L8.09812 7.1188C8.1928 7.04067 8.26883 6.9437 8.32097 6.83441C8.3731 6.72511 8.4001 6.60616 8.4001 6.4858C8.4001 6.36544 8.3731 6.24649 8.32097 6.1372C8.26883 6.02791 8.1928 5.93084 8.09812 5.85271L2.06885 0.859849C1.91654 0.729038 1.72118 0.654668 1.51752 0.650034V0.650226Z" />
                </svg>
              </button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default SurveyNav;
