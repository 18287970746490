const Spinner = (props) => {
    const { helperText, size = '', color = '' } = props;
    
    const spinnerStyle = {...{ color: color || '' }, 
        ...( size ? { width: size, height: size } : {} )};

    return (
        <div className="spinner-border app-spinner" role="status" style={spinnerStyle}>
            <span className="visually-hidden">{ helperText }</span>
        </div> 
    );
}

export default Spinner;