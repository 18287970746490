import { createContext, useReducer } from "react";

const appSettings = {
  clientIp: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "AUTHENTICATED":
      return { clientIp: action.payload.ip };
    default:
      throw new Error();
  }
};

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, appSettings);

  return <AppContext.Provider value={[state, dispatch]}>{props.children}</AppContext.Provider>;
};
