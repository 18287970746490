import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { useState } from "react";
import { Outlet } from "react-router-dom";

export default function ProtectedAdminRoutes() {
  const auth = getAuth();
  const [isSignedIn, setIsSignedIn] = useState();

  onAuthStateChanged(auth, (user) => {
    const regex = /^[a-z]+?@activecomply\.com$/gm;

    if (user && user.email?.match(regex)) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
      //signOut(auth);
    }
  });

  if (isSignedIn === undefined) {
    return <Loading />;
  }

  if (isSignedIn) {
    return (
      <>
        <nav className="navbar navbar-light bg-light">
          <div className="container-fluid d-flex justify-content-end">
            <button type="button" className="btn btn-link" onClick={() => signOut(auth)}>
              Sign Out
            </button>
          </div>
        </nav>
        <Outlet />
      </>
    );
  }

  return <SignIn />;
}

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorCode, setErrorCode] = useState();

  function signin(event) {
    event.preventDefault();

    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        clearError();
        //const user = userCredential.user;
        window.reload();
      })
      .catch((error) => {
        setErrorCode(error.code);
      });
  }

  function clearError() {
    setErrorCode();
  }

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="card" style={{ width: "30rem" }}>
        <form onSubmit={signin}>
          <div className="card-body">
            <h4 className="card-title text-center">Sign In</h4>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(val) => {
                  clearError();
                  setEmail(val.target.value);
                }}
                className="form-control"
                id="email"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(val) => {
                  clearError();
                  setPassword(val.target.value);
                }}
                className="form-control"
                id="password"
              />
            </div>

            <div
              className="alert alert-danger p-2 mb-0 mt-3"
              role="alert"
              style={{ visibility: errorCode ? "visible" : "hidden" }}
            >
              <h6 className="text-center m-0">{errorCode ?? "no error code"}</h6>
            </div>
          </div>
          <div className="card-footer text-muted p-3">
            <button type="submit" className="w-100 btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function Loading() {
  return (
    <div className="position-relative h-100">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="spinner-border app-spinner" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}
