import SurveyResponseService from "../services/SurveyResponseService";
import SurveyService from "../services/SurveyService";
import { autoId } from "../utils/StringHelpers";
import { useNavigate, useParams, redirect } from "react-router-dom";
import { useLocalStorage } from "../utils/useLocalStorage";
import NavHeader from "./NavHeader";
import logo from "../assets/active-comply-logo.svg";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import errorReporter from "../services/ErrorReporter";

export const startSurvey = (params) => {
  const surveyInProgress = findSurveyInProgress(params.surveyId);

  if (surveyInProgress) {
    return null;
  } else {
    return initializeSurvey(params.surveyId).then(({ survey, surveyResponse, localSurveyUrl }) => {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          console.log("auth signout success");
        })
        .catch((error) => {
          // An error happened.
          console.error("auth signout error", error);
        });

      setSurveyInProgress(survey, surveyResponse, localSurveyUrl);

      return redirect(localSurveyUrl);
    });
  }
};

export const removeSurveyFromLocalStorage = (surveyId) => {
  try {
    const item = window.localStorage.getItem("surveysInProgress");

    if (item) {
      let surveysInProgress = JSON.parse(item);
      delete surveysInProgress.surveys[surveyId];

      if (surveysInProgress.surveys?.length > 0) {
        window.localStorage.setItem("surveysInProgress", JSON.stringify(surveysInProgress));
      } else {
        window.localStorage.removeItem("surveysInProgress");
      }
    }
  } catch (error) {
    console.error(error);
    errorReporter.report(error);
  }
};

const initializeSurvey = (surveyId) => {
  return SurveyService.fetchSurvey(surveyId).then((survey) => {
    let newSurveyResponse = {
      id: autoId(),
      accountId: survey.accountId,
      surveyName: survey.name,
      surveyId: survey.id,
      created: new Date().toISOString(),
      token: {
        id: autoId(),
        timestamp: new Date().toISOString(),
      },
    };

    newSurveyResponse.uid = newSurveyResponse.id;
    return SurveyResponseService.addSurvey(newSurveyResponse)
      .then((response) => {
        const localSurveyUrl = `/${newSurveyResponse.id}/${newSurveyResponse.token.id}`;

        return { survey, surveyResponse: newSurveyResponse, localSurveyUrl };
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

const setSurveyInProgress = (survey, surveyResponse, localSurveyUrl) => {
  try {
    const item = window.localStorage.getItem("surveysInProgress");

    let surveysInProgress = null;

    if (item) {
      surveysInProgress = JSON.parse(item);
    } else {
      surveysInProgress = { surveys: {} };
    }

    surveysInProgress.surveys[survey.id] = {
      name: survey.name,
      surveyId: survey.id,
      surveyResponseId: surveyResponse.id,
      localSurveyUrl: localSurveyUrl,
      started: new Date(),
    };

    window.localStorage.setItem("surveysInProgress", JSON.stringify(surveysInProgress));
  } catch (error) {
    console.error(error);
    errorReporter.report(error);
  }
};

const findSurveyInProgress = (surveyId) => {
  try {
    const item = window.localStorage.getItem("surveysInProgress");

    if (item) {
      let surveysInProgress = JSON.parse(item);
      return surveysInProgress.surveys[surveyId];
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    errorReporter.report(error);
  }
  return null;
};

const StartSurvey = (props) => {
  let params = useParams(); // url params
  const navigate = useNavigate();

  const [surveysInProgress, setSurveysInProgress] = useLocalStorage("surveysInProgress");
  const [surveyInProgress] = useState(surveysInProgress?.surveys[params.surveyId]);

  const continueSurvey = () => {
    navigate(surveyInProgress.localSurveyUrl);
  };

  const startNewSurvey = () => {
    delete surveysInProgress.surveys[params.surveyId];
    setSurveysInProgress(surveysInProgress);
    navigate(0);
  };

  return (
    <>
      <NavHeader logoToUse={logo} />
      <main id="page" className="d-flex justify-content-center align-items-center">
        <Card className="shadow shadow-sm border-0">
          <Card.Body className="text-center p-5">
            <Card.Title>
              <h3>Survey in Progress</h3>
            </Card.Title>
            <Container fluid>
              <Row>
                <Col>
                  <Card className="my-5">
                    <Card.Body>
                      <Card.Title>{surveyInProgress.name}</Card.Title>
                      <Card.Text>Started {new Date(surveyInProgress.started).toLocaleDateString()}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    onClick={continueSurvey}
                    size="lg"
                    style={{ color: "#ffffff" }}
                    className="w-100 mt-2"
                  >
                    Continue Survey
                  </Button>
                </Col>

                <Col xs={12}>
                  <Button
                    variant="danger"
                    size="lg"
                    style={{ color: "#ffffff" }}
                    className="w-100 mt-2"
                    onClick={startNewSurvey}
                  >
                    Start New Survey
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </main>
    </>
  );
};

export default StartSurvey;
